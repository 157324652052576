<template>
  <v-container fluid class="px-1 py-0">
    <div class="d-flex justify-center" style="width: calc(100vw - 90px)">
      <div style="width: 1280px" class="d-flex">
        <v-card
          class="border-radius-md mt-3 py-2 mx-2 elevation-2"
          style="width: 900px"
        >
          <FirmTable></FirmTable>
        </v-card>
        <v-card
          class="border-radius-md mt-3 pb-2 mx-2 elevation-2"
          style="width: 380px"
        >
          <div class="pa-4">
            <div>
              <div style="font-size: large" class="text-dark">使用说明</div>
              <div style="color: #777; font-size: 13px">
                1.新建组合<br />
                2.进入组合，添加股票或ETF<br />
                3.使用择时策略或轮动策略回测组合<br />
              </div>
            </div>
            <!-- <div>
            <div style="font-size: large" class="text-dark">使用说明</div>
            <div style="color: #777">
              1.新建组合<br />
              2.进入组合，添加股票或ETF<br />
              3.使用择时策略或轮动策略回测组合<br />
            </div>
          </div> -->
          </div>
        </v-card>
      </div>
    </div>
  </v-container>
</template>

<script>
import Bus from "@/components/Bus/bus.js";
import FirmTable from "@/components/Firm/FirmTable.vue";

export default {
  name: "Firm",
  components: { FirmTable },
  data() {
    return {};
  },
  computed: {},
  mounted() {
    Bus.$on("adminTabChange", (e) => {
      this.currentTab = e;
    });
  },
  methods: {},
};
</script>
